import { getUserData } from '~/services/user';

export function lojaUnica(loja: number | number[]) {
  const user = getUserData();
  if (typeof loja === 'number') return loja;
  if (Array.isArray(loja) && loja.length === 1) return loja[0];
  const existLoja = loja.indexOf(user.loja);
  if (existLoja !== -1 || loja.length === 0) return user.loja;
  return loja[0];
}
