import { isAfter, isValid, parseISO } from 'date-fns';
import moment from 'moment-business-days';

const formCurrency = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});
const formCurrency3 = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

interface ValidGtinResult {
  valid: boolean;
  message: string;
}

export function validaGtin(gtin: string, tipo: number): ValidGtinResult {
  const gtinSemDigito = gtin.substring(0, gtin.length - 1);
  let digito = '';

  switch (tipo) {
    case 8:
    case 12:
    case 14:
      digito = digitoGtinX3(gtinSemDigito);
      break;
    default:
      digito = digitoGtinX1(gtinSemDigito);
      break;
  }

  if (gtin.substring(gtin.length - 1, gtin.length) !== digito) {
    return {
      valid: false,
      message: 'GTIN inválido: O dígito verificador não confere.',
    };
  }
  return { valid: true, message: 'GTIN Valido' };
}

export function geraDigitoPLU(plu: string): string {
  return formatPLU(plu + digitoGtinX3(plu));
}

function digitoGtinX3(gtin: string) {
  let sum = 0;
  gtin = String(gtin);
  gtin.split('').map((num, pos) => {
    if (pos % 2 === 0) {
      sum += Number(num) * 3;
      return Number(num) * 3;
    }
    sum += Number(num);
    return Number(num);
  });

  const digito = 10 * (Math.trunc(sum / 10) + 1) - sum;
  if (digito === 10) {
    return '0';
  }
  return String(digito);
}

function digitoGtinX1(gtin: string) {
  let sum = 0;
  gtin.split('').map((num, pos) => {
    if (pos % 2 === 0) {
      sum += Number(num);
      return Number(num);
    }
    sum += Number(num) * 3;
    return Number(num) * 3;
  });

  const digito = 10 * (Math.trunc(sum / 10) + 1) - sum;
  if (digito === 10) {
    return '0';
  }
  return String(digito);
}

export function formatPLU(plu: string): string {
  return plu.padStart(8, '0');
}

export function fltRound(numero: any, dp: number): any {
  if (arguments.length !== 2) throw new Error('2 arguments required');

  const num = String(numero);
  if (num.indexOf('e+') !== -1) {
    // Can't round numbers this large because their string representation
    // contains an exponent, like 9.99e+37
    throw new Error('num too large');
  }
  if (num.indexOf('.') === -1) {
    // Nothing to do
    return num;
  }

  const parts = num.split('.');
  const beforePoint = parts[0];
  let afterPoint = parts[1];
  const shouldRoundUp = parseFloat(afterPoint[dp]) >= 5;
  let finalNumber;

  afterPoint = afterPoint.slice(0, dp);
  if (!shouldRoundUp) {
    finalNumber = `${beforePoint}.${afterPoint}`;
  } else if (/^9+$/.test(afterPoint)) {
    // If we need to round up a number like 1.9999, increment the integer
    // before the decimal point and discard the fractional part.
    finalNumber = (Number(beforePoint) + 1).toString();
  } else {
    // Starting from the last digit, increment digits until we find one
    // that is not 9, then stop
    let i = dp - 1;
    while (true) {
      if (afterPoint[i] === '9') {
        afterPoint = `${afterPoint.substr(0, i)}0${afterPoint.substr(i + 1)}`;
        i--;
      } else {
        afterPoint =
          afterPoint.substr(0, i) +
          (Number(afterPoint[i]) + 1) +
          afterPoint.substr(i + 1);
        break;
      }
    }

    finalNumber = `${beforePoint}.${afterPoint}`;
  }

  // Remove trailing zeroes from fractional part before returning
  return parseFloat(finalNumber.replace(/0+$/, ''));
}

export function transformAsCurrency(value: string | number | null): number {
  if (value === null) return 0;
  if (typeof value === 'number') return value;
  if (value === '') return 0;
  if (value.length < 6) {
    value = value.replaceAll(',', '.');
  } else {
    value = value.replaceAll('.', '').replace(',', '.');
  }
  return Number(value);
}

export const roundByRule = (valor: number, tolerancia = 0.01): number => {
  const arredondado = Math.round(valor);
  const diferenca = parseFloat((valor - arredondado).toFixed(10));

  if (Math.abs(diferenca) < tolerancia) {
    return arredondado;
  }

  return valor;
};

export function numberToText(value: number | string): string {
  // console.log('value', value, (value.toString().match(/./g) || []).length);
  // if (value.toString().match(/./g) || []).length
  // const splitLength = value.toString().split('.').length;
  // if (splitLength === 1) {
  //   return formatCurrencyAsTex3(Number(value));
  // }
  // console.log('value', value, value.toString().split('.').length);
  // value = value.toString().replaceAll('.', ',');

  // console.log(formatCurrencyAsTex3(Number(value)));
  // return value;
  return formatCurrencyAsTex3(Number(value));
}

export const formatCurrencyAsText = (
  value: number | string | undefined,
  minimumFractionDigits = 2,
  maximumFractionDigits = 3,
): string => {
  if (typeof value === 'string') return value;
  if (value === 0) return '0,00';
  if (value) {
    const formtCurrency = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    const newValue = formtCurrency.format(value);
    if (newValue === 'NaN') return '0,00';
    return newValue;
  }
  return '';
};
export const formatCurrencyAsTex3 = (value: number | undefined): string => {
  if (value) return formCurrency3.format(value);
  return '0';
};

export function getDataUtil(
  ini: string | undefined = undefined,
  dias = 0,
  fim: string | undefined = undefined,
): string {
  moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5],
  });
  const dta_inicial = ini || moment().format('YYYY-MM-DD');
  const dataUtil = moment(dta_inicial, 'YYYY-MM-DD').businessAdd(dias);
  return moment(dataUtil).format('YYYY-MM-DD');
}

export function getDiaUtil(dta: string | undefined = undefined): number {
  const ini = moment().format('YYYY-MM-DD');
  const diff = moment(ini, 'YYYY-MM-DD').businessDiff(
    moment(dta, 'YYYY-MM-DD'),
  );
  return diff;
}

export function validaCPF(value: string): boolean {
  const cpf = value.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i), 10) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9), 10)) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i), 10) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10), 10)) return false;
  return true;
}

export function validarCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  function apenasDigitos(str: any) {
    return /^\d+$/.test(str);
  }

  if (!apenasDigitos(cnpj)) {
    return false;
  }

  let soma = 0;
  let peso = 2;
  for (let i = 11; i >= 0; i--) {
    soma += parseInt(cnpj.charAt(i), 10) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }
  const digitoVerificador1 = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (parseInt(cnpj.charAt(12), 10) !== digitoVerificador1) {
    return false;
  }

  soma = 0;
  peso = 2;
  for (let i = 12; i >= 0; i--) {
    soma += parseInt(cnpj.charAt(i), 10) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }
  const digitoVerificador2 = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (parseInt(cnpj.charAt(13), 10) !== digitoVerificador2) {
    return false;
  }

  return true;
}

export function validaEmail(email: string) {
  if (email === null || email === undefined || email === '') return false;
  email = email.toLowerCase();
  const reg = /^[\w.+]+@\w+.\w{2,}(?:.\w{2})?$/;
  return reg.test(email);
}

export function formatDate(data: string): string {
  const splitedDate = data.split(' ');
  const time = splitedDate[1];
  const date = splitedDate[0];

  // reverse date to format
  let newDate: any = date.split('/');
  newDate = newDate.reverse();
  newDate = newDate.join('-');
  newDate = `${newDate} ${time}`;
  return newDate;
}

export function moneyFormat(
  value: string,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string {
  /**
   * Converter o value em String pode parecer redundante uma vez que ele é uma prop do tipo string,
   * porém pode ser que passem uma valor any para ele, o que resultará em erro e quebrará a aplicação.
   * Sendo assim, não retirar o parser do código abaixo.
   */
  return parseFloat(value).toLocaleString('pt-br', {
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export const validarChaveNFe = (chaveNfe: string): boolean => {
  const peso: number[] = [
    4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4,
    3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 0,
  ];
  let retorno = false;

  if (chaveNfe.length !== 44) retorno = false;

  const aChave: string[] = chaveNfe.replace(/\s/g, '').split('');
  let soma = 0;

  for (let i = 0; i < aChave.length; i++) {
    soma += parseInt(aChave[i], 10) * peso[i];
  }

  if (soma === 0) retorno = false;

  soma -= 11 * Math.floor(soma / 11);
  const verif: number = soma === 0 || soma === 1 ? 0 : 11 - soma;

  retorno = verif === parseInt(aChave[43], 10);

  return retorno;
};

export const strPadZeroL = (s: string, len: number): string => {
  const zero = '0';
  const trimmedString = s.trim();

  if (trimmedString.length >= len) return trimmedString;

  return zero.repeat(len - trimmedString.length) + trimmedString;
};

export const delZeroLeft = (s: string): string => {
  let result = s;

  while (result.charAt(0) === '0') {
    result = result.slice(1);
  }

  return result;
};

export const validaIntegridadeDataInicioFim = (
  inicio: string | undefined,
  fim: string | undefined,
): boolean => {
  if (typeof inicio === 'undefined' || typeof fim === 'undefined') return true;

  const dataInicio = parseISO(inicio);
  const dataFim = parseISO(fim);

  if (!isValid(dataInicio) || !isValid(dataFim)) return true;

  if (isAfter(dataInicio, dataFim)) return true;

  return false;
};

export const pontuaCpfCnpj = (value: string): string => {
  const numeros = value.replace(/\D/g, '');

  if (numeros.length === 11)
    return `${numeros.slice(0, 3)}.${numeros.slice(3, 6)}.${numeros.slice(
      6,
      9,
    )}-${numeros.slice(9)}`;

  if (numeros.length === 14)
    return `${numeros.slice(0, 2)}.${numeros.slice(2, 5)}.${numeros.slice(
      5,
      8,
    )}/${numeros.slice(8, 12)}-${numeros.slice(12)}`;

  return '';
};
