interface ItemProps {
  uuid: string;
}

export interface MasterDetailProps {
  obj_name: string;
  pk_fields: string[];
  itens: {
    insert: ItemProps[];
    update: ItemProps[];
    delete: ItemProps[];
  };
}

export type setMasterDetailProps = (
  allmasterDetail: MasterDetailProps[],
) => void;

export function findMasterDetail(
  obj_name: string,
  allmasterDetail: MasterDetailProps[],
): MasterDetailProps | undefined {
  const MasterDetailItem = allmasterDetail.find(
    (md) => md.obj_name === obj_name,
  );
  return MasterDetailItem;
}
export function addOrUpdateMasterDetail(
  allmasterDetail: MasterDetailProps[],
  MasterDetail: MasterDetailProps,
  setMasterDetail: setMasterDetailProps,
): void {
  const outrasMaster = allmasterDetail.filter(
    (i) => i.obj_name !== MasterDetail.obj_name,
  );
  setMasterDetail([...outrasMaster, MasterDetail]);
}
export function addUpdate(
  obj_name: string,
  itens: ItemProps[],
  allmasterDetail: MasterDetailProps[],
  setMasterDetail: setMasterDetailProps,
): ItemProps[] | void {
  const MasterDetail = findMasterDetail(obj_name, allmasterDetail);

  if (!MasterDetail) return [];

  const arrayUpdate = [...itens];
  MasterDetail.itens.update = arrayUpdate;
  addOrUpdateMasterDetail(allmasterDetail, MasterDetail, setMasterDetail);
}
export function addInsert(
  obj_name: string,
  itens: ItemProps[],
  allmasterDetail: MasterDetailProps[],
  setMasterDetail: setMasterDetailProps,
): ItemProps[] | void {
  const MasterDetail = findMasterDetail(obj_name, allmasterDetail);

  if (!MasterDetail) return [];

  const arrayInsert = [...itens];
  MasterDetail.itens.insert = [...MasterDetail.itens.insert, ...arrayInsert];
  addOrUpdateMasterDetail(allmasterDetail, MasterDetail, setMasterDetail);
  return [...MasterDetail?.itens?.insert];
}

async function validateUpdate(masterDetail: MasterDetailProps, uuid: string) {
  let result = true;

  function getValueField(item: any, pk_field: string) {
    const value = item[pk_field];
    return value;
  }

  const { pk_fields } = masterDetail;
  const itens = [...masterDetail.itens.insert, ...masterDetail.itens.update];
  const index = itens.findIndex((i) => i.uuid === uuid);
  if (index !== -1) {
    // eslint-disable-next-line no-restricted-syntax
    for await (const pk_field of pk_fields) {
      const value = getValueField(itens[index], pk_field);
      if (value === undefined) {
        result = false;
      }
    }
  } else {
    result = false;
  }
  return result;
}

export async function insertOrUpdate(
  obj_name: string,
  item: ItemProps,
  allmasterDetail: MasterDetailProps[],
  setMasterDetail: setMasterDetailProps,
): Promise<ItemProps[]> {
  const MasterDetail = findMasterDetail(obj_name, allmasterDetail);

  if (!MasterDetail) return [];

  if (await validateUpdate(MasterDetail, item.uuid)) {
    const arrayUpdate = [...MasterDetail.itens.update];
    const indexItemUpdate = arrayUpdate.findIndex((i) => i?.uuid === item.uuid);
    if (indexItemUpdate !== -1) {
      arrayUpdate[indexItemUpdate] = item;
    }

    MasterDetail.itens.update = [...arrayUpdate];
  } else {
    const arrayInsert = [...MasterDetail.itens.insert];
    const indexItemInsert = arrayInsert.findIndex((i) => i?.uuid === item.uuid);
    if (indexItemInsert === -1) {
      arrayInsert.push(item);
    } else {
      arrayInsert[indexItemInsert] = item;
    }
    MasterDetail.itens.insert = [...arrayInsert];
  }

  addOrUpdateMasterDetail(allmasterDetail, MasterDetail, setMasterDetail);

  return [...MasterDetail?.itens?.insert, ...MasterDetail?.itens?.update];
}
export function deleteItens(
  obj_name: string,
  uuid: string,
  allmasterDetail: MasterDetailProps[],
  setMasterDetail: setMasterDetailProps,
): ItemProps[] {
  const MasterDetail = findMasterDetail(obj_name, allmasterDetail);

  if (!MasterDetail) return [];

  let arrayInsert = [...MasterDetail.itens.insert];
  let arrayUpdate = [...MasterDetail.itens.update];
  let arrayDelete = [...MasterDetail.itens.delete];

  const indexInsert = arrayInsert.findIndex((i) => i.uuid === uuid);

  if (indexInsert !== -1 && arrayInsert.length > 0) {
    arrayInsert = arrayInsert.filter((i) => i.uuid !== uuid);
    MasterDetail.itens.insert = arrayInsert;
  }

  const indexUpdate = arrayUpdate.findIndex((i) => i.uuid === uuid);

  if (indexUpdate !== -1 && arrayUpdate.length > 0) {
    const itemDelete = arrayUpdate[indexUpdate];
    arrayUpdate = arrayUpdate.filter((i) => i.uuid !== uuid);
    arrayDelete = [...arrayDelete, itemDelete];
    MasterDetail.itens.update = arrayUpdate;
    MasterDetail.itens.delete = arrayDelete;
  }

  addOrUpdateMasterDetail(allmasterDetail, MasterDetail, setMasterDetail);

  return [...MasterDetail?.itens?.insert, ...MasterDetail?.itens?.update];
}
