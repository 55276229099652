function eIndefinido(objeto: any) {
  return typeof objeto === typeof undefined;
}

function tamanhoNaoE(string: string, tamanho?: number) {
  if (eIndefinido(tamanho)) {
    tamanho = 9;
  }

  return string.length !== tamanho;
}

function tamanhoE(string: any, tamanho: number) {
  return !tamanhoNaoE(string, tamanho);
}

function serie(de: number, ate: number) {
  const resultado = [];

  while (de <= ate) {
    resultado.push(de++);
  }

  return resultado;
}

function primeiros(string: string, quantidade?: number) {
  if (eIndefinido(quantidade)) {
    quantidade = 8;
  }

  return string.substring(0, quantidade);
}

function substracaoPor11SeMaiorQue2CasoContrario0(valor: any) {
  return valor < 2 ? 0 : 11 - valor;
}

function mod(valor: any, multiplicadores?: any, divisor?: any) {
  if (eIndefinido(divisor)) {
    divisor = 11;
  }

  if (eIndefinido(multiplicadores)) {
    multiplicadores = serie(2, 9);
  }

  let i = 0;

  return (
    valor.split('').reduceRight(function (anterior: any, atual: any) {
      if (i > multiplicadores.length - 1) {
        i = 0;
      }

      return multiplicadores[i++] * parseInt(atual, 10) + anterior;
    }, 0) % divisor
  );
}

function calculoTrivial(valor: string, base?: any, validarTamanho?: boolean) {
  if (!validarTamanho && tamanhoNaoE(valor)) {
    return false;
  }

  if (eIndefinido(base)) {
    base = primeiros(valor);
  }

  const digito = substracaoPor11SeMaiorQue2CasoContrario0(mod(base));
  return valor === base + digito;
}

function naoComecaCom(string: string, valor: string) {
  return string.substring(0, valor.length) !== valor;
}

function entre(valor: any, limiteInferior: number, limiteSuperior: number) {
  if (typeof valor === 'string') {
    valor = parseInt(valor, 10);
  }

  return valor >= limiteInferior && valor <= limiteSuperior;
}

const funcoes: any = {
  ba: (valor: string) => {
    if (tamanhoNaoE(valor, 8) && tamanhoNaoE(valor)) {
      return false;
    }

    const base = primeiros(valor, valor.length - 2);
    let primeiroDigito, segundoDigito;

    const segundoMultiplicador = serie(2, 7);
    const primeiroMultiplicador = serie(2, 8);

    let primeiroResto, segundoResto;

    if (tamanhoE(valor, 9)) {
      segundoMultiplicador.push(8);
      primeiroMultiplicador.push(9);
    }

    if ('0123458'.split('').indexOf(valor.substring(0, 1)) !== -1) {
      segundoResto = mod(base, segundoMultiplicador, 10);
      segundoDigito = segundoResto === 0 ? 0 : 10 - segundoResto;

      primeiroResto = mod(base + segundoDigito, primeiroMultiplicador, 10);
      primeiroDigito = primeiroResto === 0 ? 0 : 10 - primeiroResto;
    } else {
      segundoResto = mod(base, segundoMultiplicador);
      segundoDigito = substracaoPor11SeMaiorQue2CasoContrario0(segundoResto);

      primeiroResto = mod(base + segundoDigito, primeiroMultiplicador);
      primeiroDigito = substracaoPor11SeMaiorQue2CasoContrario0(primeiroResto);
    }

    return valor === base + primeiroDigito + segundoDigito;
  },

  se: (valor: string) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    return calculoTrivial(valor);
  },
  al: (valor: string) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    if (naoComecaCom(valor, '24')) {
      return false;
    }

    if ('03578'.split('').indexOf(valor.substring(2, 3)) === -1) {
      return false;
    }

    const base = primeiros(valor);

    let resto = mod(base) * 10;
    // eslint-disable-next-line operator-assignment
    resto = resto - Math.floor(resto / 11) * 11;

    const digito = resto === 10 ? 0 : resto;

    return valor === base + digito;
  },

  pb: (valor: string) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    return calculoTrivial(valor);
  },
  rn: (valor: string) => {
    if (tamanhoNaoE(valor) && tamanhoNaoE(valor, 10)) {
      return false;
    }

    if (naoComecaCom(valor, '20')) {
      return false;
    }

    const base = valor.substring(0, valor.length - 1);

    const multiplicadores = serie(2, 9);
    if (tamanhoE(valor, 10)) {
      multiplicadores.push(10);
    }

    const resto = (mod(base, multiplicadores) * 10) % 11;
    const digito = resto === 10 ? 0 : resto;

    return valor === base + digito;
  },

  ap: (valor: string) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    if (naoComecaCom(valor, '03')) {
      return false;
    }

    const base = primeiros(valor);

    let p, d;

    if (entre(base, 3000001, 3017000)) {
      p = 5;
      d = 0;
    } else if (entre(base, 3017001, 3019022)) {
      p = 9;
      d = 1;
    } else {
      p = 0;
      d = 0;
    }

    const resto = mod(p + base, [2, 3, 4, 5, 6, 7, 8, 9, 1]);

    let digito;
    if (resto === 1) {
      digito = 0;
    } else if (resto === 0) {
      digito = d;
    } else {
      digito = 11 - resto;
    }

    return valor === base + digito;
  },

  rr: (valor: string) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    if (naoComecaCom(valor, '24')) {
      return false;
    }

    const base = primeiros(valor);
    const digito = mod(base, [8, 7, 6, 5, 4, 3, 2, 1], 9);

    return valor === base + digito;
  },

  am: (valor: string) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    return calculoTrivial(valor);
  },

  ro: (valor: string) => {
    let base, digito;

    if (tamanhoE(valor, 9)) {
      base = valor.substring(3, 8);
      digito = substracaoPor11SeMaiorQue2CasoContrario0(mod(base));

      return valor === valor.substring(0, 3) + base + digito;
    }
    if (tamanhoE(valor, 14)) {
      base = primeiros(valor, 13);
      digito = substracaoPor11SeMaiorQue2CasoContrario0(mod(base));

      return valor === base + digito;
    }
    return false;
  },

  rj: (valor: string) => {
    if (tamanhoNaoE(valor, 8)) {
      return false;
    }

    const base = primeiros(valor, 7);
    const digito = substracaoPor11SeMaiorQue2CasoContrario0(
      mod(base, serie(2, 7)),
    );

    return valor === base + digito;
  },

  sc: (valor: string) => {
    return calculoTrivial(valor);
  },

  pi: (valor: string) => {
    return calculoTrivial(valor);
  },

  es: (valor: string) => {
    return calculoTrivial(valor);
  },

  pr: (valor: string) => {
    if (tamanhoNaoE(valor, 10)) {
      return false;
    }

    const base = primeiros(valor);

    const restoPrimeiro = mod(base, serie(2, 7));
    const primeiro = 11 - restoPrimeiro >= 10 ? 0 : 11 - restoPrimeiro;

    const restoSegundo = mod(base + primeiro, serie(2, 7));
    const segundo = 11 - restoSegundo >= 10 ? 0 : 11 - restoSegundo;

    return valor === base + primeiro + segundo;
  },

  pa: (valor: string) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    if (naoComecaCom(valor, '15')) {
      return false;
    }

    return calculoTrivial(valor);
  },

  ce: (valor: string) => {
    if (tamanhoNaoE(valor, 9)) {
      return false;
    }
    const digitos: any = valor.slice(0, -1);
    const verificador = valor.slice(-1);
    const pesos = [9, 8, 7, 6, 5, 4, 3, 2];

    let resultado = 0;

    for (let i = 0; i < digitos.length; i++) {
      resultado += digitos[i] * pesos[i];
    }
    const restoDivisao = resultado % 11;
    const subtrairModulo = 11 - restoDivisao;

    if (subtrairModulo === 10 || subtrairModulo === 11) {
      return Number(verificador) === 0;
    }

    return subtrairModulo === Number(verificador);
  },

  pe: (valor: string) => {
    const base = valor.substring(0, valor.length - 2);

    const restoPrimeiro = mod(base);
    const primeiro = 11 - restoPrimeiro >= 10 ? 0 : 11 - restoPrimeiro;

    const restoSegundo = mod(base + primeiro);
    const segundo = 11 - restoSegundo >= 10 ? 0 : 11 - restoSegundo;

    return valor === base + primeiro + segundo;
  },

  ma: (valor: any) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    if (naoComecaCom(valor, '12')) {
      return false;
    }

    return calculoTrivial(valor);
  },

  ac: (valor: any) => {
    if (tamanhoNaoE(valor, 13)) {
      return false;
    }

    if (naoComecaCom(valor, '01')) {
      return false;
    }

    const base = primeiros(valor, 11);

    const primeiroDigito = substracaoPor11SeMaiorQue2CasoContrario0(mod(base));
    const segundoDigito = substracaoPor11SeMaiorQue2CasoContrario0(
      mod(base + primeiroDigito),
    );

    return valor === base + primeiroDigito + segundoDigito;
  },

  rs: (valor: string) => {
    if (tamanhoNaoE(valor, 10)) {
      return false;
    }

    const base = primeiros(valor, 9);
    return calculoTrivial(valor, base, true);
  },
  mt: (valor: string) => {
    if (tamanhoNaoE(valor, 11) && tamanhoNaoE(valor)) {
      return false;
    }

    const digitos: any = valor.slice(0, -1);
    const verificador = valor.slice(-1);
    const pesos = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    let resultado = 0;

    for (let i = 0; i < digitos.length; i++) {
      resultado += digitos[i] * pesos[i];
    }
    const restoDivisao = resultado % 11;
    const subtrairModulo = 11 - restoDivisao;

    if (restoDivisao === 0 || restoDivisao === 1) {
      return Number(verificador) === 0;
    }
    return subtrairModulo === Number(verificador);
  },

  sp: (valor: string) => {
    valor = valor.toUpperCase();

    let segundaBase;

    if (valor.substr(0, 1) === 'P') {
      if (tamanhoNaoE(valor, 13)) {
        return false;
      }

      const base = valor.substring(1, 9);
      segundaBase = valor.substring(10, 13);
      const resto = mod(base, [10, 8, 7, 6, 5, 4, 3, 1]).toString();
      const digito = resto.length > 1 ? resto[1] : resto[0];

      // eslint-disable-next-line prefer-template
      return valor === 'P' + base + digito + segundaBase;
    }
    if (tamanhoNaoE(valor, 12)) {
      return false;
    }

    const primeiraBase = primeiros(valor);
    segundaBase = valor.substring(9, 11);

    const primeiroResto = mod(
      primeiraBase,
      [10, 8, 7, 6, 5, 4, 3, 1],
    ).toString();
    const primeiro =
      primeiroResto.length > 1 ? primeiroResto[1] : primeiroResto[0];

    const segundoResto = mod(
      primeiraBase + primeiro + segundaBase,
      serie(2, 10),
    ).toString();
    const segundo = segundoResto.length > 1 ? segundoResto[1] : segundoResto[0];

    return valor === primeiraBase + primeiro + segundaBase + segundo;
  },

  mg: (valor: string) => {
    if (tamanhoNaoE(valor, 13)) {
      return false;
    }

    const base = primeiros(valor, 11);

    // eslint-disable-next-line prefer-template
    const baseComZero = valor.substring(0, 3) + '0' + valor.substring(3, 11);

    let i = 0;
    const produtorioLiteral = baseComZero
      .split('')
      .reduceRight(function (anterior, atual) {
        if (i > [2, 1].length - 1) {
          i = 0;
        }

        return (
          ([2, 1][i++] * parseInt(atual, 10)).toString() + anterior.toString()
        );
      }, '')
      .split('')
      .reduce(function (anterior, atual) {
        return anterior + Math.floor(Number(atual));
      }, 0);

    let primeiro =
      (Math.floor(produtorioLiteral / 10) + 1) * 10 - produtorioLiteral;
    if (primeiro === 10) {
      primeiro = 0;
    }

    const segundo = substracaoPor11SeMaiorQue2CasoContrario0(
      mod(base + primeiro, serie(2, 11)),
    );

    return valor === base + primeiro + segundo;
  },

  to: (valor: string) => {
    if (tamanhoNaoE(valor) && tamanhoNaoE(valor, 11)) {
      return false;
    }

    let base;

    if (tamanhoE(valor, 11)) {
      if (['01', '02', '03', '99'].indexOf(valor.substring(2, 4)) === -1) {
        return false;
      }

      base = valor.substring(0, 2) + valor.substring(4, 10);
    } else {
      base = primeiros(valor);
    }

    const digito = substracaoPor11SeMaiorQue2CasoContrario0(mod(base));

    return valor === valor.substring(0, valor.length - 1) + digito;
  },

  go: (valor: string) => {
    if (tamanhoNaoE(valor)) {
      return false;
    }

    if (['10', '11', '15'].indexOf(valor.substring(0, 2)) === -1) {
      return false;
    }

    const base = primeiros(valor);

    if (base === '11094402') {
      return valor.substr(8) === '1' || valor.substr(8) === '0';
    }

    const resto = mod(base);
    let digito;

    if (resto === 0) {
      digito = 0;
    } else if (resto === 1) {
      if (entre(base, 10103105, 10119997)) {
        digito = 1;
      } else {
        digito = 0;
      }
    } else {
      digito = 11 - resto;
    }

    return valor === base + digito;
  },

  ms: (valor: string) => {
    if (naoComecaCom(valor, '28')) {
      return false;
    }

    return calculoTrivial(valor);
  },

  df: (valor: string) => {
    if (tamanhoNaoE(valor, 13)) {
      return false;
    }

    const base = primeiros(valor, 11);

    const primeiro = substracaoPor11SeMaiorQue2CasoContrario0(mod(base));
    const segundo = substracaoPor11SeMaiorQue2CasoContrario0(
      mod(base + primeiro),
    );

    return valor === base + primeiro + segundo;
  },
};

function lookup(ie: any) {
  const resultado = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const estado in funcoes) {
    if (funcoes[estado](ie)) {
      resultado.push(estado);
    }
  }

  if (tamanhoE(resultado, 0)) {
    return false;
  }
  return resultado;
}

export function validar(ie: string, estado: string): any {
  if (eIndefinido(estado) || estado === null) {
    estado = '';
  }

  estado = estado.toLowerCase();

  if (estado !== '' && !(estado in funcoes)) {
    throw new Error('estado não é válido');
  }

  if (eIndefinido(ie)) {
    throw new Error('ie deve ser fornecida');
  }
  if (Array.isArray(ie)) {
    return ie.map(function (ie2: any) {
      return validar(ie2, estado);
    });
  }
  if (typeof ie !== 'string') {
    throw new Error('ie deve ser string ou array de strings');
  }
  if (ie.match(/^ISENT[O|A]$/i)) {
    return true;
  }

  ie = ie.replace(/[.|\-|\\/|\s]/g, '');
  if (estado === '') {
    return lookup(ie);
  }
  const teste = /^\d+$/;
  if (teste.test(ie) || estado === 'sp') {
    return funcoes[estado](ie);
  }
  return false;
}
