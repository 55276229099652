const getOptimalTextColor = (bgColor: string): '#fff' | '#000' => {
  const [r, g, b] = hexToRgb(bgColor);
  const bgLuminance = luminance(r, g, b);

  // Define os limiares de luminosidade
  const whiteLuminance = luminance(255, 255, 255);
  const blackLuminance = luminance(0, 0, 0);

  // Calcula as proporções de contraste
  const contrastWithWhite = (whiteLuminance + 0.05) / (bgLuminance + 0.05);
  const contrastWithBlack = (bgLuminance + 0.05) / (blackLuminance + 0.05);

  // Retorna a cor com maior contraste
  return contrastWithWhite > contrastWithBlack ? '#fff' : '#000';
};

export const getColorOdd = (hex: string, variancy: number): string => {
  const lumLevel = getColorLuminosity(hex);
  if (lumLevel > 127) variancy *= -1;
  return adjustLuminosity(hex, variancy);
};

const hexToRgb = (hex: string): number[] => {
  // Remove o hash no início, se houver
  hex = hex.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  // eslint-disable-next-line no-bitwise
  const r = (bigint >> 16) & 255;
  // eslint-disable-next-line no-bitwise
  const g = (bigint >> 8) & 255;
  // eslint-disable-next-line no-bitwise
  const b = bigint & 255;

  return [r, g, b];
};

const luminance = (r: number, g: number, b: number): number => {
  // Convert to a scale of 0 to 1
  r /= 255;
  g /= 255;
  b /= 255;

  // Apply the transformation
  r = r <= 0.03928 ? r / 12.92 : ((r + 0.055) / 1.055) ** 2.4;
  g = g <= 0.03928 ? g / 12.92 : ((g + 0.055) / 1.055) ** 2.4;
  b = b <= 0.03928 ? b / 12.92 : ((b + 0.055) / 1.055) ** 2.4;

  // Calculate the luminance
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

// 0 - 255
const getColorLuminosity = (hex: string): number => {
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const nib = hex.split('');

  const r = parseInt(nib[1] + nib[2], 16);
  const g = parseInt(nib[3] + nib[4], 16);
  const b = parseInt(nib[5] + nib[6], 16);

  const result = (r * 299 + g * 587 + b * 114) / 1000;

  return result;
};

// Função para ajustar a cor com base na luminosidade
const adjustColor = (color: number, luminosity: number): number => {
  // Se luminosidade é positiva, clarear
  if (luminosity > 0) {
    return Math.round(Math.min(255, color + (255 - color) * luminosity));
  }
  // Se luminosidade é negativa, escurecer
  return Math.round(Math.max(0, color + color * luminosity));
};

const adjustLuminosity = (hex: string, luminosity: number): string => {
  // Remove o hash no início, se houver
  hex = hex.replace(/^#/, '');
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');

  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Converte os valores hexadecimais para RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Ajusta a luminosidade de cada componente de cor
  const red = adjustColor(r, luminosity).toString(16).padStart(2, '0');
  const green = adjustColor(g, luminosity).toString(16).padStart(2, '0');
  const blue = adjustColor(b, luminosity).toString(16).padStart(2, '0');

  // Retorna a cor ajustada no formato hexadecimal
  return `#${red}${green}${blue}`;
};

export const getColorLine = (
  hex: string,
  variancy: number,
  idxRecord: number,
): string => {
  if (idxRecord % 2 === 0) return hex;
  return getColorOdd(hex, variancy);
};

export const getColorText = (
  hex: string,
  variancy: number,
  idxRecord: number,
): '#fff' | '#000' => {
  const bgColor = getColorLine(hex, variancy, idxRecord);
  return getOptimalTextColor(bgColor);
};
