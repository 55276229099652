export function validDiffFiltros(
  defaultFilters: any[],
  editFilter: any[],
): boolean {
  try {
    if (defaultFilters.length !== editFilter.length) {
      return false;
    }
    if (defaultFilters.length > 0 && editFilter.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const newFilter of editFilter) {
        const oldfilter = defaultFilters.find(
          (item) => item.cod_campo === newFilter.cod_campo,
        );
        if (!oldfilter) {
          return false;
        }

        const keys = Object.keys(oldfilter);
        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
          if (key !== 'index') {
            if (oldfilter[key] !== newFilter[key]) {
              return false;
            }
          }
        }
      }
    }
    return true;
  } catch (erro: any) {
    return false;
  }
}
